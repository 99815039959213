import React from 'react';
import PortableText from '../portableText';

interface Props {
  authorName: string;
  authorSubheader: string;
  authorBio: any[];
}

const LandingText: React.FC<Props> = ({
  authorName,
  authorBio,
  authorSubheader,
}) => {
  return (
    <div className="flex flex-col max-w-2xl">
      <h1 className="text-5xl mx-auto sm:mx-0 pb-1">{authorName}</h1>
      <h1 className="text-2xl leading-7 pb-5 font-thin mx-auto sm:mx-0">
        {authorSubheader}
      </h1>
      <PortableText blocks={authorBio} />
    </div>
  );
};

export default LandingText;
