import React from 'react';
import Image from 'gatsby-plugin-sanity-image';

interface Props {
  image: any;
}

const Avatar: React.FC<Props> = ({ image }) => {
  return (
    <div className="border-primary border-8 rounded-full inline-block w-min">
      <div className="p-1">
        <Image
          className="max-w-md w-72 h-72 md:w-96 md:h-96 rounded-full object-cover object-top"
          {...image}
          width={400}
          height={400}
        />
      </div>
    </div>
  );
};

export default Avatar;
