import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import Layout from '../containers/layout';
import Avatar from '../components/landingPage/Avatar';
import LandingText from '../components/landingPage/LandingText';
import SocialMedia from '../components/landingPage/SocialMedia';
import { IndexPageQueryQuery } from '../../graphql-types';

interface Props {
  data?: IndexPageQueryQuery;
  errors: any;
}

const IndexPage: React.FC<Props> = ({ data, errors }) => {
  // TODO: Implement an error-page
  if (errors) {
    return (
      <Layout>
        <h1>Halla</h1>
      </Layout>
    );
  }

  const site = (data || {}).site;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <SEO
        title={site.title ?? ''}
        description={site.description ?? ''}
        keywords={site.keywords}
      />
      <div className="grid grid-cols-1 sm:grid-cols-2 items-center h-full mx-4 md:mx-16 lg:mx-32 gap-0 sm:gap-4 mt-8 lg:mt-32 sm:mt-12">
        <div className="flex-col space-y-4 mx-auto order-2 sm:order-1 mt-10">
          <LandingText
            authorSubheader={data?.author?.subheader ?? ''}
            authorName={data?.author?.name ?? ''}
            authorBio={data?.author?._rawBio ?? []}
          />
          <div className="justify-center sm:justify-start">
            <SocialMedia />
          </div>
        </div>
        <div className="mx-auto order-1 sm:order-2">
          <Avatar image={data?.author?.image} />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  # Have to define these fragments to make
  fragment SanityImage on SanityImageInterface {
    hotspot {
      height
      width
      x
      y
    }
    crop {
      bottom
      left
      right
      top
    }
    asset {
      _id
    }
  }

  fragment Image on SanityImage {
    hotspot {
      height
      width
      x
      y
    }
    crop {
      bottom
      left
      right
      top
    }
    asset {
      _id
    }
  }

  fragment SanityImageWithPreview on SanityImageInterface {
    ...Image
    asset {
      metadata {
        preview: lqip
      }
    }
  }

  query IndexPageQuery {
    author: sanityAuthor {
      name
      image {
        ...SanityImageWithPreview
      }
      _rawBio
      subheader
    }

    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    posts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`;

export default IndexPage;
